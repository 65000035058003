import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';
import PropTypes from 'prop-types';

const Container = styled.div`
  background-color: ${props => props.bg ? props.bg : '#161616'};
  background-image: url(${props => props.bgimage ? props.bgimage : null});
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  min-height: ${props => props.full ? '100vh' : 'auto'};
  @media ${styles.breakpoints.mobile} {
    min-height: 30vh;
  }
`;

const Intro = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${styles.spaces.xLarge};
  padding-bottom: ${styles.spaces.xxLarge};
  padding-right: ${styles.spaces.xLarge};
  padding-left: ${styles.spaces.xLarge};
  margin: 0 auto;
  max-width: 1100px;
  font-size: ${styles.fontSizes.xxxxLarge};
  font-family: ${styles.fontFamilies.system};
  font-weight: ${styles.fontWeights.semiBold};
  min-height: 100vh;
  @media ${styles.breakpoints.mobile} {
    padding-top: ${styles.spaces.xLarge};
    // min-height: 80vh;
  }
  color: ${styles.colors.white};
  & a {
    font-weight: ${styles.fontWeights.regular}
  }
  p.small {
    font-size: ${styles.fontSizes.large};
  }
  @media ${styles.breakpoints.tabletPortrait} {
    p {
      font-size: ${styles.fontSizes.xxxLarge};
    }
    p.small {
      font-size: ${styles.fontSizes.medium};
    }
    padding-top: ${styles.spaces.xSmall};
    padding-bottom: ${styles.spaces.xxLarge};
    padding-right: ${styles.spaces.large};
    padding-left: ${styles.spaces.large};
  }
  @media ${styles.breakpoints.mobile} {
    p {
      font-size: ${styles.fontSizes.xxLarge};
    }
    p.small {
      font-size: ${styles.fontSizes.xSmall};
    }
    font-size: ${styles.fontSizes.medium}
    padding-top: ${styles.spaces.xSmall};
    padding-bottom: ${styles.spaces.xxLarge};
    padding-right: ${styles.spaces.medium};
    padding-left: ${styles.spaces.medium};
  }
`;

class IntroHomeComponent extends React.Component {
  render() {
    return (
      <div className="notch">
        <Container  {...this.props}>
          <Intro>
            {/* <Title1 title={this.props.title} />  */}
            {this.props.children}
          </Intro>
        </Container>
      </div>
    );
  }
}

IntroHomeComponent.propTypes = {
  title: PropTypes.string
};

export default IntroHomeComponent
